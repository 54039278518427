import React from "react";
import "./App.css";
import MainPage from "./components/MainPage";

function App() {
  return (
    <div className="bg-image">
      <div className="centered">
        <MainPage />
      </div>
    </div>
  );
}

export default App;
