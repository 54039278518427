import { TransportType } from "./TransportType";

export class CostCalculator {
  static _getDefaultZones() {
    const zones = [
      { fromKilometer: 0, toKilometer: 10, basePrice: 18 },
      {
        fromKilometer: 11,
        toKilometer: Number.MAX_SAFE_INTEGER,
        basePrice: 18,
        kilometerPrice: 1.6,
        kilometerPriceStart: 10,
      },
    ];

    return zones;
  }

  static _getOegkZones() {
    const zones = [
      { fromKilometer: 0, toKilometer: 5, basePrice: 10 },
      {
        fromKilometer: 6,
        toKilometer: Number.MAX_SAFE_INTEGER,
        basePrice: 0,
        kilometerPrice: 1.8,
        kilometerPriceStart: 0,
      },
    ];

    return zones;
  }

  static _getSozialfahrtCanWalk() {
    const zones = [
      { fromKilometer: 0, toKilometer: 5, basePrice: 10 },
      {
        fromKilometer: 6,
        toKilometer: Number.MAX_SAFE_INTEGER,
        basePrice: 0,
        kilometerPrice: 1.8,
        kilometerPriceStart: 0,
      },
    ];

    return zones;
  }

  static _getSozialfahrtCarryChairAndWheelChair() {
    const zones = [
      { fromKilometer: 0, toKilometer: 15, basePrice: 26.5 },
      { fromKilometer: 16, toKilometer: 30, basePrice: 53 },
      { fromKilometer: 31, toKilometer: 50, basePrice: 79.5 },
      { fromKilometer: 51, toKilometer: 75, basePrice: 106 },
    ];

    return zones;
  }

  static getCostsForSozialfahrt(
    kilometer,
    startPostalCode,
    endPostalCode,
    transportType
  ) {
    if (
      startPostalCode === "6020" &&
      endPostalCode === "6020" &&
      transportType === TransportType.Walking
    ) {
      return (14).toFixed(2);
    }

    let zones;

    switch (transportType) {
      case TransportType.Walking:
        zones = CostCalculator._getSozialfahrtCanWalk();
        return CostCalculator._getZonePrice(zones, kilometer);

      case TransportType.Sitting:
      case TransportType.Wheelchair:
        // No calculation for this
        if (kilometer > 75) {
          return null;
        }

        zones = CostCalculator._getSozialfahrtCarryChairAndWheelChair(
          zones,
          kilometer
        );
        return CostCalculator._getZonePrice(zones, kilometer);

      default:
        return null;
    }
  }

  static getCosts(kilometer, insurance, startPostalCode, endPostalCode) {
    let zones;

    if (insurance === "oegk" || insurance === "kuf") {
      if (startPostalCode === "6020" && endPostalCode === "6020") {
        return (14).toFixed(2);
      }

      zones = CostCalculator._getOegkZones();
      return CostCalculator._getZonePrice(zones, kilometer);
    } else {
      zones = CostCalculator._getDefaultZones();
      return CostCalculator._getZonePrice(zones, kilometer);
    }
  }

  static _getZonePrice(zones, kilometer) {
    kilometer = Math.round(kilometer);

    const filteredZones = zones.filter(
      (x) => kilometer >= x.fromKilometer && kilometer <= x.toKilometer
    );

    if (filteredZones.length === 1) {
      const usedZone = filteredZones[0];

      let result = usedZone.basePrice;

      if (usedZone.kilometerPrice) {
        let kilometerPriceStart = usedZone.kilometerPriceStart;

        if (!kilometerPriceStart) {
          kilometerPriceStart = 0;
        }

        result +=
          (kilometer - usedZone.kilometerPriceStart) * usedZone.kilometerPrice;
      }

      return result.toFixed(2);
    }

    return null;
  }
}
